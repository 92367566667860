


export const WalletPage = () => {
    return (
        <div>
            <h1>Wallet</h1>
        </div>
    );
}
