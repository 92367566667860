import { getTelegramData } from '@telegram-apps/telegram-ui/dist/helpers/telegram';


class TelegramInitData {
    IsTelegramUser = false;
    TelegramInitData;
    IsFirstUser = false;
    constructor(isTelegramUser:boolean,telegramInitData:any,IsFirstUser:boolean) {
        this.IsTelegramUser = isTelegramUser;
        this.TelegramInitData = telegramInitData;
        this.IsFirstUser = IsFirstUser;
    }
}


export const setBackgroundAsSecondary = () => {
    const telegramData = getTelegramData();
    let initData = new TelegramInitData(false,null,false);
    if (!telegramData) {
      return initData;
    }
    initData.IsTelegramUser = true;
    initData.TelegramInitData = telegramData;
    initData.IsFirstUser = true;
    telegramData.setBackgroundColor(telegramData.themeParams.secondary_bg_color);
    return initData;
};
