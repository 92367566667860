import {useState} from "react";
import {Tabbar} from "@telegram-apps/telegram-ui";
import { Icon28Devices } from '@telegram-apps/telegram-ui/dist/icons/28/devices';
import { Icon28Attach } from '@telegram-apps/telegram-ui/dist/icons/28/attach';
import { Icon28Heart} from "@telegram-apps/telegram-ui/dist/icons/28/heart";
import {Outlet, useNavigate} from "react-router-dom";



const tabs = [
    {id:0,path: "/", text: '首页', Icon: Icon28Heart},
    {id:1,path: "/discover", text: '发现', Icon: Icon28Attach},
    {id:2,path: "/wallet", text: '钱包', Icon: Icon28Devices},
];

export const Navbar = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const navigate = useNavigate();
    return (
       <div>
           <Tabbar>
               {tabs.map(({ id, path,text, Icon }) => (
                   <Tabbar.Item
                       key={id}
                       text={text}
                       selected={id === currentTab}
                       onClick={() => {
                           setCurrentTab(id)
                           navigate(path)
                       }}
                   >
                       <Icon />
                   </Tabbar.Item>
               ))}
           </Tabbar>
           <Outlet />
       </div>
    );
};
