import {Banner,List,Section,Cell,Avatar} from "@telegram-apps/telegram-ui";
import React from "react";

export const HomePage = () => {
    return (
        <div>
            <Banner
                header="Big Pumm Bot功能广场"
                subheader="Bot交易从未如此简单"
                type="section"
            >
            </Banner>
            <List>
                <Section header="Solana工具箱">
                    <Cell before={<Avatar size={48}  acronym="跟"/>}>跟单交易</Cell>
                    <Cell before={<Avatar size={48}  acronym="狙"/>}>狙击新币</Cell>
                    <Cell before={<Avatar size={48}  acronym="限"/>}>限价买卖</Cell>
                </Section>
                <Section header="EVM工具箱">
                    <Cell before={<Avatar size={48}  acronym="跟"/>}>跟单交易</Cell>
                    <Cell before={<Avatar size={48}  acronym="狙"/>}>狙击新币</Cell>
                    <Cell before={<Avatar size={48}  acronym="限"/>}>限价买卖</Cell>
                </Section>
                <Section header="钱包管理">
                    <Cell before={<Avatar size={48}  acronym="归"/>}>批量归集</Cell>
                    <Cell before={<Avatar size={48}  acronym="创"/>}>批量创建</Cell>
                    <Cell before={<Avatar size={48}  acronym="分"/>}>批量分发</Cell>
                </Section>
                <Section header="一键发币">
                    <Cell before={<Avatar size={48}  acronym="R"/>}>Solana Raydium一键发币</Cell>
                </Section>
            </List>
        </div>
    );
}
