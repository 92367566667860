import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {Navbar} from '../components/nav/navbar'
import {HomePage} from '../views/HomePages/home';
import {DiscoveryPage} from '../views/DiscoveryPage/discovery';
import {WalletPage} from '../views/WalletPage/wallet';

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navbar />}>
                    <Route index element={<HomePage />} />
                    <Route path="discover" element={<DiscoveryPage />} />
                    <Route path="wallet" element={<WalletPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
