import React from 'react';

import { AppRoot } from '@telegram-apps/telegram-ui';
import {AppRouter} from "./router/AppRouter";


export const App = () => (
    <AppRoot>
        <AppRouter />
    </AppRoot>
);
